.home-banner .home-bd-title,
.home-banner .home-sm-para,
.home-banner .home-sm-title,
h4.course-text,
h4.course-text-1,
h4.years-of-experience,
p.about-us-dialog-text,
p.years-of-experience {
    font-family: "GT Walsheim Pro"
}

.home-banner,
.testimonails,
.testimonails .review-bg {
    background-repeat: no-repeat
}

@media only screen and (max-width:1300px) {
    ul.social-links1 {
        left: 3% !important
    }
}

@media only screen and (max-width:1230px) {
    ul.social-links1 {
        left: 1% !important
    }
}

.home-banner {
    width: 100%;
    background-size: cover;
    position: relative;
    min-height: 100vh
}

.Home_Page h4.course-text-1,
.Home_Page p.course-text-2 {
    color: #30231d;
    font-family: "GT Walsheim Pro";
    font-weight: 600
}

.Home_Page .home-banner .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 98vh
}

.Home_Page .home-about .btn-container {
    margin-bottom: 4rem;
    margin-top: 0
}

.Home_Page .home-about .img-control {
    overflow: visible !important;
    height: 100%
}

.Home_Page .text-block {
    bottom: -30px;
    padding: 8px !important
}

.Home_Page .course-image {
    margin: 0 !important
}

@media only screen and (max-width:992px) {
    .testimonails-wrapper {
        width: 100% !important
    }
}

.home-banner .home-sm-title {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase
}

.home-banner .home-bd-title {
    font-size: 66px;
    font-weight: 700;
    color: #fff
}

.home-banner .home-sm-para {
    font-size: 18px;
    color: #fff
}

.home-banner .banner_btn {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #fff;
    background-color: #543828;
    padding: 10px 40px
}

ul.social-links1 {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding-left: 0;
    position: absolute;
    left: 5%;
    top: 40%;
    bottom: 41%
}

ul.social-links1 li {
    list-style: none
}

.social-icon-color {
    color: #fff
}

.about-us-image-container {
    position: absolute;
    top: -3px;
    right: -38px;
    color: #000;
    max-width: 240px
}

.about-us-image {
    position: absolute;
    bottom: 40px;
    color: #fff;
    margin-left: 30px;
    display: flex;
    flex-direction: row
}

.title-service-main,
p.about-us-dialog-text {
    justify-content: center;
    text-align: center
}

h4.years-of-experience {
    font-size: 16px;
    margin-top: 10px
}

p.years-of-experience {
    font-size: 38px
}

.about-us-dialog-img,
.about-us-image-img {
    width: 100%
}

.text-block {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border: 1px solid #fbfbfb;
    box-shadow: 0 0 15px #85858573;
    display: flex;
    border-radius: 3px
}

h4.course-text {
    font-size: 16px
}

.course-text-main {
    text-align: center
}

h4.course-text-1 {
    font-size: 12px
}

p.course-text-2 {
    font-size: 39px;
    margin-bottom: 0
}

.course-image {
    background-image: linear-gradient(90deg, #543828, #7d4f38);
    width: 80px !important;
    height: auto !important;
    margin: 14px;
    padding: 21px;
    border-radius: 6px
}

p.about-us-dialog-text {
    position: absolute;
    top: 20px;
    left: -20px;
    right: 0;
    padding: 0 24px;
    margin: 0 20px;
    width: 100% !important;
    font-size: 12px !important;
    color: #704c33
}

.title-service-main,
h2.title-second-main {
    color: #3f2d26;
    font-size: 30px;
    font-family: "GT Walsheim Pro"
}

.home-service .card-row .col-lg-4 {
    width: 100%;
    max-width: 100%
}

.home-service ul.slick-dots li button:before {
    border: 2px solid #744836;
    border-radius: 50%;
    content: "";
    display: block;
    height: 10px;
    opacity: 1;
    padding: 0;
    width: 10px
}

.home-service ul.slick-dots li.slick-active button:before {
    background: #744836
}

.home-service .slick-next,
.home-service .slick-prev {
    background-color: #88563b;
    border-radius: 4px;
    height: 26px;
    width: 26px
}

.home-service .slick-prev:before {
    color: #fff;
    content: "\f104";
    font-family: FontAwesome;
    opacity: 1
}

.home-service .slick-next:before {
    color: #fff;
    content: "\f105";
    font-family: FontAwesome;
    opacity: 1
}

.home-service .slick-next:hover,
.home-service .slick-prev:hover {
    background-color: #30231d
}

.testimonails {
    padding: 80px 0;
    background-image: url(../img/reviewText.png);
    background-position: center 160px;
    margin-top: 6rem;
    background-size: 78%
}

.testimonails .review-bg {
    background-size: contain
}

.testimonails-wrapper .rating-wrapper ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center
}

.testimonails-wrapper .rating-wrapper ul li {
    margin: 0 8px 0 0;
    color: #ffd400
}

.testimonails-wrapper {
    background-image: linear-gradient(90deg, #543828, #7d4f38);
    padding: 18px;
    box-shadow: 0 0 9px 3px #bbbbbb26 !important;
    box-shadow: 0 0 5px 1px rgb(0 0 0 / 16%);
    -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, .16);
    margin-top: 44px;
    margin-bottom: 5px;
    min-height: 240px;
    width: 100% !important;
    margin-left: 5px;
    transform: scale(.9);
    border-radius: 10px
}

.testimonails .slick-prev:before {
    content: "\f104";
    font-family: FontAwesome;
    background: url("../img/Group 9868.png") 1px 9px/contain no-repeat;
    padding: 29px;
    opacity: 1
}

.testimonails .slick-prev {
    left: -10%
}

.slick-next,
.slick-prev {
    top: 59%
}

.testimonails .slick-next:before {
    content: "\f105";
    font-family: FontAwesome;
    background: url("../img/Group 9868.png") -1px 9px/contain no-repeat;
    padding: 29px;
    opacity: 1;
    z-index: 9
}

.testimonails .slick-next {
    right: -5%;
    z-index: 99
}

img.video-image-btn {
    position: absolute;
    top: 40%;
    width: 70px
}

.testimonails-wrapper::after {
    content: url(../img/ColorOverlay.png);
    position: absolute;
    right: 12%;
    top: -54px
}

.testimonails-wrapper::before {
    content: url(../img/ColorOverlay.png);
    position: absolute;
    left: 14%;
    top: -54px;
    z-index: 99
}

.testimonails-wrapper p {
    font-family: "GT Walsheim Pro";
    color: #fff;
    font-size: 14px;
    margin-top: 22px;
    padding: 0 68px
}

.testimonails-wrapper h6 {
    font-family: "GT Walsheim Pro";
    color: #fff;
    font-size: 16px
}

.testimonails h2 {
    color: #3f2d26;
    font-family: "GT Walsheim Pro";
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px
}

p.testmonials {
    color: #88563b;
    font-size: 16px;
    font-family: Helvetica
}

.home-about .para,
.home-about .points,
.home-about .tags-title,
.home-about .title,
.home-service .service-card .title,
.home-service .service-card a,
.service-name {
    font-family: "GT Walsheim Pro"
}

.testimonails-wrapper img {
    margin-top: -62px !important;
    margin: 0 auto
}

.home-about {
    padding: 100px 0
}

.home-about .container {
    max-width: 1400px !important
}

.home-about .tags-title {
    color: #88563b;
    margin: 100px 0 0
}

.home-about .about-shadow {
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0;
    background: #fff
}

.home-about .title {
    font-size: 32px;
    color: #3f2d26
}

.home-about .para {
    color: #a89a93;
    font-size: 18px
}

.home-about .points {
    color: #30231d;
    font-size: 16px
}

.home-about .home-icon {
    color: #88563b;
    margin-right: 15px;
    font-size: 20px
}

.home-about .btn-container {
    margin-bottom: 90px;
    margin-top: 35px
}

.home-about .img-control {
    width: 100%;
    overflow: hidden;
    position: relative
}

.home-about .img-control img {
    width: 100%;
    object-fit: cover;
    height: 100%
}

section.home-about {
    content: "";
    height: 100%;
    width: 100%;
    background: url("../img/Path8710.png") left center no-repeat, url(../img/Aboutus.png) 3rem -12rem/90% no-repeat;
    object-fit: contain;
    z-index: -10;
    padding-bottom: 0
}

.service-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #3f2d26d0;
    font-size: 18px;
    font-weight: 600
}

.home-service {
    background: url(../img//Services.png) left 24rem/100% no-repeat, url(../img/brownrec.png) 13rem 12rem/27rem 30rem no-repeat;
    padding: 6rem 0 4rem
}

.home-service .service-card {
   margin: 60px 20px !important;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, .24) 0 3px 8px;
    padding: 20px;
    background: #fff
}

.home-service .sm-tag {
    font-family: Helvetica;
    color: #88563b;
    font-size: 18px;
    text-align: center
}

.home-service .title {
    font-size: 32px;
    color: #3f2d26;
    text-align: center;
    font-weight: 500
}

.home-service .service-card .img-box {
    width: 100%;
    height: 100%;
    overflow: hidden
}

.home-service .service-card .img-box img {
    width: 100%;
    height: 280px !important;
    object-fit: cover
}

.head_home_all_same {
    font-size: 28px !important;
    font-weight: 600 !important
}

.head_home_all_same_small {
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-bottom: 0
}

.Home_Page .content_box {
    width: auto
}

.Home_Page .star-svg {
    height: 15px;
    width: 15px
}

.Home_Page .rating-wrapper {
    margin-top: 0
}

.Home_Page .testimonails-wrapper h6 {
    margin-bottom: 0
}

.home-service .service-card .title {
    font-size: 18px;
    color: #3f2d26;
    font-weight: 500
}

.home-service .service-card .price-cont {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.home-service .service-card .price,
p.service-price {
    color: #806c65;
    font-size: 16px;
    font-weight: 500;
    font-family: "GT Walsheim Pro"
}

.home-service .service-card a {
    color: #3f2d26;
    font-size: 16px;
    font-weight: 500
}

.home-client {
    background: linear-gradient(90deg, #543828, #7d4f38);
    background-image: url(../img/clientbg1.png);
    padding: 7rem 0;
    background-size: cover
}

.home-client .tag-sm {
    color: #fff;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px
}

.home-client .title {
    font-size: 32px;
    color: #fff;
    text-align: center;
    font-family: "GT Walsheim Pro";
    margin-bottom: 80px
}

.home-client .home_browbox_headingss,
.home-client .mile-box .content_box h5 {
    font-size: 13px;
    font-weight: 500;
    font-family: Poppins-Regular
}

.home-client .home_browbox_headingss {
    align-items: center;
    text-align: center;
    color: #fff;
    padding-top: 15px
}

.Home_Page .max_width_icon_home {
    max-width: 40px
}

.home-client .mile-box {
    color: #fff;
    display: flex;
    align-items: center;
    background: #8e6550;
    border: 1px solid #8e6550;
    padding: 0 15px 15px;
    margin: 0 7px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 10%);
    justify-content: center
}

.home-client .mile-box .content_box h3 {
    font-size: 39px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0
}

.home-client .mile-box .content_box h3 span.symbol {
    font-size: 23px;
    margin-left: 5px
}

.home-client .mile-box .icon_box figure {
    margin: 0
}

.home-client .mile-box .icon_box {
    margin-right: 15px
}

.video_sec {
    position: relative;
    margin-top: -13%
}

.video_sec .ply-btn {
    position: absolute;
    bottom: -7rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0
}

section.video_sec .preview_img {
    transform: translate(0, 120px)
}

@keyframes scale {
    0% {
        transform: scale(1)
    }

    100% {
        transform: scale(1.1)
    }
}